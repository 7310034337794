<template>
  <div class="elite-tabs-wrapper-content">
    <div class="col-12">

      <div class="transaction">
        <h3>
          <span v-if="dpaFile.transaction_type == DEPOSIT">{{
            $t("DPA_PPA.DEPOSIT_DEMAND")
          }}</span>
          <span v-else>{{ $t("DPA_PPA.WITHDRAW_DEMAND") }}</span>
        </h3>
        <div class="row">
          <div class="col-12">
            <div class="balance-badge details-amount d-flex align-items-center">
              <in-out-badge :type="dpaFile.transaction_type" class="mr-2" />
              <amount-badge :amount="dpaFile.total_amount" />
            </div>
          </div>
        </div>
      </div>

        
      <dl class="row">
        <dt class="col-sm-4">{{ $t("DPA_PPA.CODE") }}</dt>
        <dd class="col-sm-8">
          {{ dpaFile.code }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("DPA_PPA.TOTAL_TRANSACTIONS") }}</dt>
        <dd class="col-sm-8">
          {{ dpaFile.total_transactions }}
        </dd>
      </dl>

    
      <dl class="row">
        <dt class="col-sm-4">{{ $t("DPA_PPA.STATUS") }}</dt>
        <dd class="col-sm-8">
          <status-badge :status="dpaFile.status" />
        </dd>
      </dl>

      <div v-if="dpaFile.file_name" class="credit-debit-details">
        <div class="genereted-file">
        <h3>{{ $t("DPA_PPA.FILE") }}</h3>
        <object-details
          :data="getDetails('file')"
          :with-trad="true"
          trad-path="DPA_PPA"
        />
        <el-divider />
      </div>
      <div v-if="dpaFile.processed_file_name" class="bank-response-file">
        <h3>{{ $t("DPA_PPA.PROCESSING") }}</h3>
        <object-details
          :data="getDetails('response')"
          :with-trad="true"
          trad-path="DPA_PPA"
        />
      </div>
      </div>

      <dl
        class="row"
        v-if="dpaFile.organization && $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="dpaFile.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ dpaFile.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ dpaFile.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>import {
  DEPOSIT,
  WITHDRAW
} from "@/components/ElixioWallet/constants/common";
  import {
  Divider
} from "element-ui";
import ObjectDetails from "@/components/ElixioWallet/components/atoms/ObjectDetails.vue";
import InOutBadge from "@/components/ElixioWallet/components/atoms/InOutBadge.vue";
import AmountBadge from "@/components/ElixioWallet/components/atoms/AmountBadge.vue";
import StatusBadge from "@/components/ElixioWallet/components/atoms/StatusBadge.vue";
export default {
  name: "dpa-file-view-global",

  components: {
    [Divider.name]: Divider,
    ObjectDetails,
    InOutBadge,
    AmountBadge,
    StatusBadge
  },

  props: ["dpaFile"],

  data() {
    return {
      DEPOSIT: DEPOSIT,
      WITHDRAW: WITHDRAW,
    };
  },

  created() {},

  methods: {
    dpaFileUpdated() {
      this.$emit("dpaFileUpdated", true);
    },
    
    getDetails(type) {
      if(type=='file'){
        return {
          FILE_NAME: this.dpaFile.file_name,
          GENERATED_AT: this.$formatDate(this.dpaFile.created_at),
          SENT_AT: this.dpaFile.sent_at==null?'-':this.$formatDate(this.dpaFile.sent_at),
        };
      }else{
        let responseData = {
          FILE_NAME: this.dpaFile.processed_file_name,
          PROCESSED_AT: this.$formatDate(this.dpaFile.processed_at),
          SENT_AT: this.$formatDate(this.dpaFile.sent_at),
        };
        if(this.dpaFile.error_message){
          responseData.ERROR_MESSAGE=this.dpaFile.error_message
        }
        return responseData;
      }
    },
  },

  mounted() {},

  watch: {
    dpaFile(dpaFile) {},
  },
};
</script>
