var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-3"},[_c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.checkAddressInformation.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-group-wrapper"},[_c('div',{staticClass:"add-form"},[_c('div',{staticClass:"form-group-wrapper-item"},[_c('div',{staticClass:"w-100"},[(
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
              !_vm.hideOrganization
            )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.dpaFile.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.dpaFile.id},on:{"organizationChanged":(organizationId) => {
                  _vm.dpaFile.organization.id = organizationId;
                  _vm.onFormChanged();
                }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1)]),_c('div',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":`${_vm.$t('DPA_PPA.TRANSACTION_TYPE')} (*)`,"placeholder":_vm.$t('DPA_PPA.TRANSACTION_TYPE')}},[_c('el-select',{attrs:{"label":_vm.$t('DPA_PPA.TRANSACTION_TYPE'),"placeholder":_vm.$t('DPA_PPA.TRANSACTION_TYPE')},on:{"change":(transaction_type) => {
                _vm.dpaFile.transaction_type = transaction_type;
                _vm.onFormChanged();
              }},model:{value:(_vm.dpaFile.transaction_type),callback:function ($$v) {_vm.$set(_vm.dpaFile, "transaction_type", $$v)},expression:"dpaFile.transaction_type"}},_vm._l((_vm.TRANSACTIONS_TYPES),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":value ? _vm.$t(`WALLET.${value}`) : ''}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transaction_type}})],1)]),(_vm.dpaFile.organization.id && _vm.dpaFile.transaction_type)?_c('div',{staticClass:"form-group-wrapper-item w-100"},[_c('div',{staticClass:"form-group-wrapper-item w-100 transaction-selector my-3"},[_c('transaction-selector',{ref:"alltransactionList",attrs:{"payment-gateway":"DPA-PPA","selected-organization":_vm.dpaFile.organization.id,"selected-type":_vm.dpaFile.transaction_type,"selected-statut":_vm.STATUS_PENDING},on:{"update:selected":_vm.handleSelection}})],1),(_vm.apiValidationErrors.transaction_ids)?_c('span',{staticClass:"form-error"},[_c('small',{staticClass:"argon-error",attrs:{"name":"transaction_ids"}},[_vm._v(_vm._s(_vm.$t("DPA_PPA.SELECT_TRANSACTIONS")))])]):_vm._e()]):_vm._e(),(_vm.dpaFile.organization.id && _vm.dpaFile.transaction_type)?_c('div',{staticClass:"form-group-wrapper-item w-100 my-3"},[_c('div',{staticClass:"send-later"},[_c('el-checkbox',{attrs:{"border":""},model:{value:(_vm.sendNow),callback:function ($$v) {_vm.sendNow=$$v},expression:"sendNow"}},[_vm._v(_vm._s(_vm.$t("DPA_PPA.SEND_TO_BANK")))])],1)]):_vm._e(),_c('div',{staticClass:"add-form"},[_c('div',{staticClass:"form-group-wrapper-item"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading || _vm.processing}},[(_vm.loading || _vm.processing)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.dpaFile.id ? _vm.$t("DPA_PPA.EDIT_FILE") : _vm.$t("DPA_PPA.ADD_FILE"))+" ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }