<template>
  <div class="elite-tabs-wrapper-content">
    <div class="page-wrapper-table slect-list">
      <h3>{{ $t("WALLET.SELECT_TRANSACTIONS") }}</h3>
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('WALLET.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <base-input class="dates" placeholder="Dates">
          <flat-picker
            :config="{
              allowInput: true,
              mode: 'range',
              locale: flatPickrLocale,
            }"
            class="form-control datepicker"
            v-model="dateRange"
            placeholder="Dates"
          >
          </flat-picker>
        </base-input>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
        <!-- <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button> -->
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush selector"
            header-row-class-name="thead-light"
            :data="transactions"
            @sort-change="sortChange"
            @selection-change="updateSelection"
            ref="transactionTable"
            row-class-name="clickable-row"
            @row-click="toggleRowSelection"
            highlight-current-row
            show-selection-column
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column
              type="selection"
              width="50"
              align="center"
            ></el-table-column>

            <el-table-column
              :label="$t('WALLET.NUMERO')"
              prop="code"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.meta.code ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="null" prop="status" min-width="70">
              <template v-slot:header>
                <ArrowsUpDown />
              </template>
              <template v-slot="{ row }">
                <in-out-badge :type="row.type" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_STATUS')"
              prop="status"
              min-width="200"
            >
              <template v-slot="{ row }">
                <el-tooltip
                  v-if="row.meta.status_comment"
                  :content="row.meta.status_comment"
                  placement="top"
                >
                  <div class="d-f">
                    <status-badge :status="row.meta.status" />
                    <i class="fa fa-info-circle ml-1 text-danger"></i>
                  </div>
                </el-tooltip>
                <status-badge v-else :status="row.meta.status" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_AMOUNT')"
              prop="amount"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <amount-badge :amount="row.amount" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_LABEL')"
              prop="label"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.meta.label ?? "" }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>

            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_FILES)"
                >
                  <a
                    type="text"
                    @click="viewTransaction(row.id)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("WALLET.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("WALLET.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>

    <el-divider />
    

      <div class="selected-transaction" v-if="selectedRows.length">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("WALLET.TRANSACTIONS") }}</dt>
        <dd class="col-sm-8">
          {{selectedRows.length}}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("WALLET.TOTAL_AMOUNT_SELECTED") }}</dt>
        <dd class="col-sm-8">
          {{selectedAmount}}
        </dd>
      </dl>
      </div>

    <el-drawer
      :title="$t('WALLET.TRANSACTION')"
      :visible.sync="showTransactionDetails"
      direction="rtl"
      size="50%"
      :append-to-body="true"
    >
      <div class="transaction-details px-5">
        <view-transaction-component
          :id="currentTransactionId"
          :show-options="false"
          @transactionStatusUpdated="refreshData($event)"
        />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Vue from "../../main";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import BasePagination from "../atoms/BasePagination.vue";
import {
  TRANSACTION_STATUS_LIST,
  STATUS_DRAFT,
  POSSIBLE_HOLDER_ATTRIBUTES,
  TRANSACTION_TYPES,
} from "../../constants/common";
import StatusBadge from "../atoms/StatusBadge.vue";
import InOutBadge from "../atoms/InOutBadge.vue";
import AmountBadge from "../atoms/AmountBadge.vue";
import ArrowsUpDown from "../../Icons/ArrowsUpDown.vue";
import ViewTransactionComponent from "../ViewTransactionComponent.vue";

export default {
  name: "TransactionsList",

  components: {
    flatPicker,
    BasePagination,
    StatusBadge,
    InOutBadge,
    AmountBadge,
    ArrowsUpDown,
    ViewTransactionComponent,
  },

  props: {
    walletId: {
      type: Number,
      default: null,
    },
    paymentGateway: {
      type: String,
      default: null,
    },
    showGlobal: {
      type: Boolean,
      default: false,
    },
    selectedStatut: {
      type: String,
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
    selectedOrganization: {
      type: [String, Number],
      default: null,
    },
  },
  mixins: [requestErrorMixin],

  data() {
    return {
      flatPickrLocale: French,
      dateRange: null,
      query: null,
      selectedRows: [],
      selectedAmount: 0,
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      transactions: [],
      loading: true,
      TRANSACTION_STATUS_LIST: TRANSACTION_STATUS_LIST,
      TRANSACTION_TYPES: TRANSACTION_TYPES,
      STATUS_DRAFT: STATUS_DRAFT,
      currentTransactionId: null,
      showTransactionDetails: false,
      POSSIBLE_HOLDER_ATTRIBUTES: POSSIBLE_HOLDER_ATTRIBUTES,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedStatut: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
      this.dateRange = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    viewTransaction(id) {
      this.currentTransactionId = id;
      this.showTransactionDetails = true;
    },
    refreshData(status = "draft") {
      this.$emit("transactionStatusUpdated", status);
      this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
            sort: this.sort,
            "filter.search": this.query,
            "filter.meta.status": this.selectedStatut,
            "filter.meta.payment_gateway": this.paymentGateway,
            "filter.type": this.selectedType,
            "page.number": this.pagination.currentPage,
            "page.size": this.pagination.perPage,
            ...(vDateRange.length == 2
              ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : vDateRange.length == 1?{
                created_at:vDateRange[0]
              }:{}),
        };

        if (this.walletId != null) {
          params.wallet_id = this.walletId;
        }

        if (this.selectedOrganization != null) {
          params.holder_type = "organization";
          params.holder_id = this.selectedOrganization;
        }
        const response = await this.$axios.get(`/elixio-wallet/transactions`, {
          params,
        });
        this.transactions = response.data.data;
        this.total = response.data.total;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    updateSelection(selected) {
      this.selectedRows = selected;
      this.selectedAmount = selected.reduce(
        (sum, row) => sum + parseFloat(row.amount || 0),
        0
      );
      const transactionIds = selected.map((row) => row.id);

      // Émission de l'événement vers le composant parent
      this.$emit("update:selected", {
        transaction_ids: transactionIds,
        total_transactions: selected.length,
        total_amount: this.selectedAmount,
      });
    },

    toggleRowSelection(row) {
      this.$refs.transactionTable.toggleRowSelection(row);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    refreshList() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>
