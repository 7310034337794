<template>
  <div class="m-3">
    <form
      ref="profile_form"
      @submit.prevent="checkAddressInformation"
      @keydown.enter.prevent="() => {}"
    >
      <div class="form-group-wrapper">
        <div class="add-form">
        <div class="form-group-wrapper-item">
          <!-- Organization -->
          <div class="w-100">
            <base-input
              :label="`${$t('COMMON.ORGANIZATION')} (*)`"
              :placeholder="$t('COMMON.ORGANIZATION')"
              v-if="
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                !hideOrganization
              "
            >
              <organization-selector
                :organization="dpaFile.organization.id"
                :filterable="true"
                :showAll="false"
                @organizationChanged="
                  (organizationId) => {
                    dpaFile.organization.id = organizationId;
                    onFormChanged();
                  }
                "
                :disabled="!!dpaFile.id"
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.organization" />
          </div>
        </div>
        <div class="form-group-wrapper-item">
          <base-input
              :label="`${$t('DPA_PPA.TRANSACTION_TYPE')} (*)`"
              :placeholder="$t('DPA_PPA.TRANSACTION_TYPE')"
            >
            <el-select
              :label="$t('DPA_PPA.TRANSACTION_TYPE')"
              :placeholder="$t('DPA_PPA.TRANSACTION_TYPE')"
              v-model="dpaFile.transaction_type"
              @change="
                (transaction_type) => {
                  dpaFile.transaction_type = transaction_type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in TRANSACTIONS_TYPES"
                :key="key"
                :value="value"
                :label="value ? $t(`WALLET.${value}`) : ''"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.transaction_type" />
        </div>
      </div>
        <div class="form-group-wrapper-item w-100" v-if="dpaFile.organization.id && dpaFile.transaction_type" >
          <div class="form-group-wrapper-item w-100 transaction-selector my-3">
              <transaction-selector 
                ref="alltransactionList"
                payment-gateway="DPA-PPA" 
                :selected-organization="dpaFile.organization.id"
                :selected-type="dpaFile.transaction_type"
                :selected-statut="STATUS_PENDING"
                @update:selected="handleSelection"
                />
          </div>
          <span class="form-error" v-if="apiValidationErrors.transaction_ids">
            <small name="transaction_ids" class="argon-error">{{$t("DPA_PPA.SELECT_TRANSACTIONS")}}</small>
          </span>
        </div>

        <div v-if="dpaFile.organization.id && dpaFile.transaction_type" class="form-group-wrapper-item w-100 my-3">
          <div class="send-later">
            <el-checkbox v-model="sendNow" border>{{
              $t("DPA_PPA.SEND_TO_BANK")
            }}</el-checkbox>
          </div>
        </div>

        <div class="add-form">
        <div class="form-group-wrapper-item">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{ dpaFile.id ? $t("DPA_PPA.EDIT_FILE") : $t("DPA_PPA.ADD_FILE") }}
          </base-button>
        </div>
      </div>
      </div>
    </form>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import TransactionSelector from "@/components/ElixioWallet/components/lists/TransactionSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { defaultAddressInformation } from "@/constants/defaultAddressInformation";
import "flatpickr/dist/flatpickr.css";
import { TRANSACTIONS_TYPES } from "@/constants/dpaPpa";
import { STATUS_PENDING } from "@/components/ElixioWallet/constants/common";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TransactionSelector
  },

  mixins: [formMixin],

  props: [
    "dpaFileData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let dpaFileData = { ...this.dpaFileData };
    dpaFileData = this.$fillUserOrganizationData(dpaFileData);
    return {
      sendNow:false,
      TRANSACTIONS_TYPES: TRANSACTIONS_TYPES,
      dpaFile: dpaFileData,
      phoneTypesOptions,
      dpaFiles: [],
      total: 0,
      showModal: false,
      defaultAddressInformation,
      processing: false,
      STATUS_PENDING:STATUS_PENDING
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    handleSelection({ transaction_ids, total_transactions, total_amount }) {
      this.dpaFile.transaction_ids = transaction_ids;
      this.dpaFile.total_transactions = total_transactions;
      this.dpaFile.total_amount = total_amount;
    },
    async handleSubmit() {
      const checkUniqueDpaFile = await this.checkDpaFileUniqueInformation();
      if (checkUniqueDpaFile) {
        await this.sendInformation();
      } else {
        return;
      }
    },

    async sendInformation() {
      let dpaFileData = cloneDeep(this.dpaFile);
      dpaFileData = this.$fillUserOrganizationData(dpaFileData);
      dpaFileData.sendNow = this.sendNow;
      this.$emit("dpaFileSubmitted", dpaFileData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.dpaFile?.organization_id}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "organization",
        };

        if (this.dpaFile?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.dpaFile?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("dpaFiles/list", params);
        this.dpaFiles = this.$store.getters["dpaFiles/list"];
        this.total = this.$store.getters["dpaFiles/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkDpaFileUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.dpaFile?.id;
        default:
          return false;
      }
    },

    checkAddressInformation() {
      this.handleSubmit();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    dpaFileData(dpaFileData) {
      if (dpaFileData) {
        this.dpaFile = {
          ...this.dpaFile,
          ...cloneDeep(dpaFileData),
        };
      }
    },
  },
};
</script>
