export default {
  type: "dpa-files",
  transaction_type: null,
  file_name: null,
  processed_file_name: null,
  file_creation_number: null,
  status: null,
  total_transactions: null,
  total_amount: null,
  transaction_ids: null,
  sent_at: null,
  processed_at: null,
  error_message: null,
  relationshipNames: [
    "organization",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
};