<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("DPA_PPA.FILES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openDpaFileCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_DPA_FILES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("DPA_PPA.ADD_DPA_FILE")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_DPA_FILES)"
              :objectType="'dpa-files'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <dpa-file-list-table
        @onViewDpaFile="openDpaFileViewModal"
        @onDeleteDpaFile="deleteDpaFile"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewDpaFileModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewDpaFileModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'DPA_FILE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("DPA_PPA.VIEW_DPA_FILE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openDpaFile"
                :objectType="'dpa-files'"
                :objectId="openDpaFile.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="confirm"
                  @click="confirmTransaction(openDpaFile)"
                >
                  <i class="fa fa-check"></i>
                  <span>{{ $t("DPA_PPA.CONFIRM_TRANSACTION") }}</span>
                </button>
                <button
                  v-if="openDpaFile.status == STATUS_GENERATED"
                  class="send"
                  @click="sendFileToBank(openDpaFile)"
                >
                  <i class="fa fa-paper-plane"></i>
                  <span>{{ $t("DPA_PPA.SEND_FILE_TO_BANK") }}</span>
                </button>
                <button
                  v-if="openDpaFile.status == STATUS_GENERATED"
                  class="delete"
                  @click="deleteDpaFile(openDpaFile)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeDpaFileModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-dpa-file-component
              v-if="openDpaFile"
              :dpaFileId="openDpaFile.id"
              :loading="loading"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditDpaFileModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditDpaFileModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'DPA_FILE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("DPA_PPA.EDIT_DPA_FILE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openDpaFileViewModal(openDpaFile)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeDpaFileModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-dpa-file-component
              v-if="openDpaFile"
              :dpaFileId="openDpaFile.id"
              @onViewDpaFile="openDpaFileViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddDpaFileModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddDpaFileModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'DPA_FILE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("DPA_PPA.ADD_DPA_FILE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeDpaFileModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-dpa-file-component @onViewDpaFile="openDpaFileViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import DpaFileListTable from "./partials/DpaFileListTable.vue";
import EditDpaFileComponent from "./components/EditDpaFileComponent.vue";
import AddDpaFileComponent from "./components/AddDpaFileComponent.vue";
import ViewDpaFileComponent from "./components/ViewDpaFileComponent.vue";
import { STATUS_GENERATED } from "@/constants/dpaPpa";

export default {
  layout: "DashboardLayout",

  components: {
    DpaFileListTable,
    NotificationSubscription,
    EditDpaFileComponent,
    AddDpaFileComponent,
    ViewDpaFileComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const dpaFileId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewDpaFileModalOpened = false;
    let isEditDpaFileModalOpened = false;
    let isAddDpaFileModalOpened = false;
    let openDpaFile = null;
    if (dpaFileId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewDpaFileModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditDpaFileModalOpened = true;
      }
      openDpaFile = { id: dpaFileId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddDpaFileModalOpened = true;
    }
    return {
      isViewDpaFileModalOpened: isViewDpaFileModalOpened,
      isEditDpaFileModalOpened: isEditDpaFileModalOpened,
      isAddDpaFileModalOpened: isAddDpaFileModalOpened,
      openDpaFile: openDpaFile,
      renderKey: 1,
      STATUS_GENERATED: STATUS_GENERATED,
      loading: false,
    };
  },

  methods: {
    openDpaFileCreateModal() {
      this.closeDpaFileModal();
      this.isAddDpaFileModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaFiles",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openDpaFileViewModal(dpaFile, reRender = false) {
      this.closeDpaFileModal();
      this.openDpaFile = dpaFile;
      this.isViewDpaFileModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaFiles",
          query: { id: this.openDpaFile.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    async sendFileToBank(dpaFile) {
      const confirmation = await swal.fire({
        text: this.$t("DPA_PPA.SEND_THIS_FILE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-success",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          this.loading = true;
          await this.$store.dispatch("dpaFiles/send", dpaFile.id);
          this.loading = false;
          this.renderKey++;
          this.closeDpaFileModal();
          this.$notify({
            type: "success",
            message: this.$t("DPA_PPA.FILE_SENT"),
          });
        }
      } catch (error) {
        this.loading = false;
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async confirmTransaction(dpaFile) {
      let requestData = {
        transaction_ids: null,
      };
      const confirmation = await swal.fire({
        text: this.$t("DPA_PPA.CONFIRM_THIS_FILE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn base-button btn-success",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          this.loading = true;
          await this.$store.dispatch("dpaFiles/confirm", {
            id: dpaFile.id,
            params: requestData,
          });
          this.loading = false;
          this.renderKey++;
          this.closeDpaFileModal();
          this.$notify({
            type: "success",
            message: this.$t("DPA_PPA.FILE_CONFIRMED"),
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    closeDpaFileModal() {
      this.isAddDpaFileModalOpened = false;
      this.isViewDpaFileModalOpened = false;
      this.isEditDpaFileModalOpened = false;
      this.openDpaFile = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaFiles",
          query: {},
        }).href
      );
    },

    async deleteDpaFile(dpaFile) {
      const confirmation = await swal.fire({
        title: this.$t("DPA_PPA.DELETE_THIS_DPA_FILE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("dpaFiles/destroy", dpaFile.id);
          this.renderKey++;
          this.closeDpaFileModal();
          this.$notify({
            type: "success",
            message: this.$t("DPA_PPA.FILE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
