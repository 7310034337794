<template>
  <div class="elite-tabs-wrapper-content">
    <log-list-table :filterSubject="dpaFile" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "dpa-file-view-logs",

  components: { LogListTable },

  props: ["dpaFile"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
