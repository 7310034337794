<template>
  <div class="container-fluid">
    <dpa-file-form
      :loading="loading"
      :dpaFileData="dpaFile"
      :formErrors="formErrors"
      @dpaFileSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultDpaFile from "../defaultDpaFile";
import DpaFileForm from "../partials/DpaFileForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    DpaFileForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      dpaFile: cloneDeep(defaultDpaFile),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(dpaFile) {
      this.loading = true;

      const dpaFileData = cloneDeep(dpaFile);
      delete dpaFileData.id;
      try {
        await this.$store.dispatch("dpaFiles/add", dpaFileData);
        this.$notify({
          type: "success",
          message: this.$t("DPA_PPA.DPA_FILE_ADDED"),
        });
        const dpaFile = await this.$store.getters["dpaFiles/dpaFile"];
        this.$emit("onViewDpaFile", dpaFile, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
