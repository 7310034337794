<template>
  <div>
    <div class="page-wrapper-table">
      <h3>{{ $t("WALLET.TRANSACTIONS_LIST") }}</h3>

      <base-input
        class="mt-3 w-100"
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />
      <div class="page-wrapper-table-body mt-3">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="filteredTransactions"
            v-loading="loading"
          >
            <!-- Colonne Numéro -->
            <el-table-column
              :label="$t('WALLET.NUMERO')"
              prop="meta.code"
              min-width="150"
            >
              <template v-slot="{ row }">
                <span>{{ row.meta?.code ?? "-" }}</span>
              </template>
            </el-table-column>

            <!-- Colonne Statut -->
            <el-table-column
              :label="$t('WALLET.TRANSACTION_STATUS')"
              prop="meta.status"
              min-width="150"
            >
              <template v-slot="{ row }">
                <status-badge :status="row.meta?.status" />
              </template>
            </el-table-column>

            <!-- Colonne Montant -->
            <el-table-column
              :label="$t('WALLET.TRANSACTION_AMOUNT')"
              prop="amount"
              min-width="150"
            >
              <template v-slot="{ row }">
                <amount-badge :amount="row.amount" />
              </template>
            </el-table-column>

            <!-- Colonne Label -->
            <el-table-column
              :label="$t('WALLET.TRANSACTION_LABEL')"
              prop="meta.label"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>{{ row.meta?.label ?? "-" }}</span>
              </template>
            </el-table-column>

            <!-- Colonne Créé à -->
            <el-table-column
              :label="$t('WALLET.TRANSACTION_CREATED_AT')"
              prop="created_at"
              min-width="200"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>

            <!-- Actions -->
            <el-table-column fixed="right" min-width="120">
              <template v-slot="{ row }">
                <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
                  <a
                    type="text"
                    @click="viewTransaction(row.id)"
                    class="table-action"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- Tiroir pour les détails -->
    <el-drawer
      :title="$t('WALLET.TRANSACTION')"
      :visible.sync="showTransactionDetails"
      :append-to-body="true"
      direction="rtl"
      size="50%"
    >
      <div class="transaction-details px-5">
        <view-transaction-component
          :id="currentTransactionId"
          :show-options="false"
          @transactionStatusUpdated="refreshList"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import StatusBadge from "../atoms/StatusBadge.vue";
import AmountBadge from "../atoms/AmountBadge.vue";
import ViewTransactionComponent from "../ViewTransactionComponent.vue";

export default {
  name: "TransactionsTable",
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },
  components: {
    StatusBadge,
    AmountBadge,
    ViewTransactionComponent,
  },

  data() {
    return {
      query: null,
      showTransactionDetails: false,
      currentTransactionId: null,
      loading: false,
    };
  },
  methods: {
    viewTransaction(id) {
      this.currentTransactionId = id;
      this.showTransactionDetails = true;
    },
    refreshList() {
      this.$emit("refresh");
    },
  },

  computed: {
    filteredTransactions() {
      if (!this.query) {
        return this.transactions;
      }
      const queryLower = this.query.toLowerCase();
      return this.transactions.filter((transaction) => {
        return (
          (transaction.meta?.code?.toLowerCase() || "").includes(queryLower) ||
          (transaction.meta?.label?.toLowerCase() || "").includes(queryLower) ||
          (transaction.meta?.status?.toLowerCase() || "").includes(
            queryLower
          ) ||
          (transaction.amount || "")
            .toString()
            .toLowerCase()
            .includes(queryLower)
        );
      });
    },
  },
};
</script>
